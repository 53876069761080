import React from "react";
import "./Dashboard.css";
import DashboardMenu from "./DashboardMenu";


const Dashboard = () => {



  return (
    <div>
      <div className="vh-100">
        <DashboardMenu></DashboardMenu>

       
      </div>
    </div>
  );
};

export default Dashboard;
