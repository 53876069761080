import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import auth from "../../firebase.init";
import BackToAdminDashboard from "./BackToAdminDashboard";

const UpdateApiKey = () => {
    const { id } = useParams();
    const [api, setApi] = useState({});
    const navigate = useNavigate();
    const [user] = useAuthState(auth);

    useEffect(() => {
        fetch(`https://leadapps-c53177894abd.herokuapp.com/api`)
            .then((res) => res.json())
            .then((info) => setApi(info[0]));
    }, []);

    const handleUpdatePayment = (event) => {
        event.preventDefault();
        const apiKey = event.target.apiKey.value;
        const UserEmail = event.target.UserEmail.value;

        const updateApiKey = {
            apiKey,
            UserEmail
        };

        const url = `https://leadapps-c53177894abd.herokuapp.com/update-api/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(updateApiKey),
        })
            .then((res) => res.json())
            .then((result) => {
                navigate("/admin/setting");
            });
    };

    return (
        <>
            <BackToAdminDashboard></BackToAdminDashboard>
            <section className="banner s2 vh-100" data-aos="fade-up" data-aos-duration={3000}>
                <div className="shape" />
                <div className="shape right" />
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h4 className="heading">
                                    Update Your Api Key
                                    <span className="arlo_tm_animation_text_word" /> <br />
                                </h4>

                                <form
                                    onSubmit={handleUpdatePayment}
                                    class="form card-box"
                                    style={{ width: "100%" }}
                                >
                                    <input
                                        required
                                        hidden
                                        type="text"
                                        name="UserEmail"
                                        value={user?.email}
                                    />
                                    <div class="container">
                                        <div class="row justify-content-center align-items-baseline">
                                            <div class="col-sm">
                                                <div class="form-group mb-3">
                                                    <input
                                                        required
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Enter apiKey"
                                                        name="apiKey"
                                                        defaultValue={api.apiKey}
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-sm">
                                                <button type="submit" class="btn circle btn-theme-effect btn-sm mt-5">
                                                    <span>Update</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UpdateApiKey;
